import React from "react"
import Section from "../Section"
import Col from "../Col"
import Container from "../Container"
import Row from "../Row"
import Title from "../Title"
import { StaticImage } from "gatsby-plugin-image"
import Paragraph from "../Paragraph"
import ArrowCta from "../ArrowCta"

const InsiderSection = () => {
  return (
    <Section>
      <Container>
        <Row justify="center" justifyLg="space-between">
          <Col col={6} lg={5} orderLg="last">
            <StaticImage src="../../images/7107_insider.png" alt="7107" />
          </Col>
          <Col lg={7}>
            <Title size={2} lg={"d2"}>
              7107 Insider
            </Title>
            <Paragraph>
            Know more about the Philippine market and trends with 7107 Insider, a highly informative investment resource that deep dives into the latest insights and developments in the country's hotel, leisure and tourism sectors.
            </Paragraph>
            <ArrowCta to="/insider" title="Read the latest issue" />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default InsiderSection
