import React from "react"
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import OurStoryHero from "../components/our_story/OurStoryHero"
import SliderSection from "../components/our_story/slider"
import QuoteSection from "../components/our_story/quote"
import InsiderSection from "../components/our_story/insider"
import LeadershipSection from "../components/our_story/leadership"
import ContactLead from "../components/ContactLead"

const OurStory = () => {
  return (
    <>
      <Seo title="Our Story" />
      <BlurredSection>
        <OurStoryHero />
        <SliderSection />
        <QuoteSection />
        <InsiderSection />
        <LeadershipSection />
        <ContactLead />
      </BlurredSection>
    </>
  )
}

export default OurStory
