import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Carousel from "../Carousel"
import styled from "styled-components"
import { BREAKPOINTS } from "../../utils/styled_config"

import ChevronLeftIcon from "../Icons/ChevronLeftIcon"
import ChevronRightIcon from "../Icons/ChevronRightIcon"

const SliderImages = () => {
  const PrevArrow = ({ onClick, className }) => {
    return (
      <ArrowButton onClick={onClick} className={className}>
        <ChevronLeftIcon />
      </ArrowButton>
    )
  }

  const NextArrow = ({ onClick, className }) => {
    return (
      <ArrowButton onClick={onClick} className={className}>
        <ChevronRightIcon />
      </ArrowButton>
    )
  }
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  const images = useStaticQuery(graphql`
    query StorySliderQuery {
      allFile(
        sort: { fields: name }
        filter: {
          name: {
            in: [
              "slider_insider_1"
              "slider_insider_2"
              "slider_insider_3"
              "slider_insider_4"
              "slider_insider_5"
              "slider_insider_6"
              "slider_insider_7"
            ]
          }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Slider>
        <Carousel settings={settings}>
          {images &&
            images.allFile.edges.map((img, idx) => (
              <div className="embla__slide" key={idx}>
                <CroppedImage>
                  <GatsbyImage image={getImage(img.node)} alt="slider" />
                </CroppedImage>
              </div>
            ))}
        </Carousel>
      </Slider>
    </>
  )
}

const Slider = styled.div`
  position: relative;

  .slick-dots {
    position: static;
  }
`

const CroppedImage = styled.div`
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 65.16%;
  overflow: hidden;
  .gatsby-image-wrapper,
  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
`

const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  z-index: 2;
  background: none;

  svg {
    font-size: 20px;
  }

  &.slick-prev {
    left: -60px;
  }

  &.slick-next {
    right: -60px;
  }

  &.slick-disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    height: 56px;
    width: 56px;

    svg {
      font-size: 28px;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.lg}) {
    display: none;
  }
`

export default SliderImages
