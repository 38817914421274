import React from "react"
import styled from "styled-components"
import Section from "../Section"
import Col from "../Col"
import Container from "../Container"
import Row from "../Row"
import Title from "../Title"
import LineVector from "../LineVector"

const QuoteSection = () => {
  return (
    <QuoteSectionStyled>
      <Container>
        <Row justify="center">
          <Col lg={9}>
            <Title size={3} lg={"d4"} mb={2}>
            It takes vision, expertise, persistence, and plain gut to make the right hotel investment decision.
            </Title>
            <LineVector heigth={12} width={154} />
          </Col>
        </Row>
      </Container>
    </QuoteSectionStyled>
  )
}

const QuoteSectionStyled = styled(Section)`
  text-align: center;
`

export default QuoteSection
