import React from "react"
import styled from "styled-components"

import Section from "../Section"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import Paragraph from "../Paragraph"
import Title from "../Title"
import Counter from "../Counter"
import SliderImages from "./SliderImages"
import Element from "../UI/Element"

const SliderSection = () => {
  return (
    <Section>
      <Container>
        <Row justify="space-between" mb={10} mbLg={20}>
          <Col lg={4}>
            <Title size="2">
              Guiding you&nbsp;
              <Element display={{ _: "none", lg: "block" }}></Element>
              from day zero
            </Title>
          </Col>
          <Col lg={8}>
            <Paragraph>
              TAJARA's unwavering commitment to fulfill our clients' visions has
              fueled the transformation of lands into thriving hospitality and
              residential developments in cities, islands and emerging
              destinations.
            </Paragraph>
          </Col>
        </Row>

        <Row mb={10} mbLg={20}>
          <Col col={6} lg={3}>
            <Counter from={0} to={25} label="Years of Experience" />
          </Col>
          <Col col={6} lg={3}>
            <Counter from={0} to={60} label="Client Portfolio" />
          </Col>
          <Col col={6} lg={3}>
            <Counter from={0} to={120} label="Projects Completed" />
          </Col>
          <Col col={6} lg={3}>
            <Counter from={0} to={70} label="Locations" />
          </Col>
        </Row>

        <Row mb={10} mbLg={20} justify="center">
          <Col lg={8}>
            <SliderImages />
          </Col>
        </Row>

        <Row justify="center">
          <Col lg={8}>
            <ParagraphLead>
              Our journeys and community influence what we build and the way we
              build. TAJARA believes in the power of collaborations. Working
              with world class designers, international hotel operators and
              top-tier contractors, has enabled us and generations of property
              owners to develop differentiating projects that are top of mind
              while enriching the livelihood of its community.
            </ParagraphLead>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const ParagraphLead = styled(Paragraph)`
  text-align: center;
`

export default SliderSection
