import React, { useRef } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { BREAKPOINTS } from "../utils/styled_config"
import Title from "./Title"
import { animate } from "framer-motion"
import Element from "./UI/Element"

const Counter = ({ from, to, label }) => {
  const ref = useRef()

  const [refNumber, inView] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px 100px 0px",
  })

  const animateMe = (from, to) => {
    animate(from, to, {
      duration: 1,
      onUpdate(value) {
        ref.current.textContent = value.toFixed(0)
      },
    })
  }

  return (
    <CounterStyled ref={refNumber}>
      {inView && animateMe(from, to)}
      <Element fontSize={{ _: "h1", lg: "d1" }} mb={2}>
        <span ref={ref}>{from}</span>+
      </Element>
      <Title size={4} lg={3}>
        {label}
      </Title>
    </CounterStyled>
  )
}

const CounterStyled = styled.div`
  text-align: center;
  margin-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    margin-bottom: 0;
  }
`

export default Counter
