import React from "react"
import styled from "styled-components"
import ScaledImage from "../ScaledImage"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Heading } from "../UI/Typography"
import Element from "../UI/Element"

const LeaderEntry = ({
  img,
  name,
  position,
  onMouseMove,
  onMouseDown,
  onMouseUp,
}) => {
  return (
    <Leader
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <ScaledImage width={260} height={330} objectPosition="top">
        <GatsbyImage image={getImage(img)} alt={name} />
      </ScaledImage>
      <Overlay>
        <Heading fontSize="h3" mb={2}>
          {name}
        </Heading>
        <Element fontWeight="bold">{position}</Element>
      </Overlay>
    </Leader>
  )
}

const Leader = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
`

const Overlay = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 5.46%, #000000 45.8%);
  padding: 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  min-height: 145px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;

  & > * {
    width: 100%;
  }
`

export default LeaderEntry
