import React from "react"
import Hero from "../Hero"
import HeroImg from "../../images/el-nido.jpg"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import Title from "../Title"
import LinedText from "../LinedText"

const OurStoryHero = () => {
  return (
    <Hero img={HeroImg} page="inner">
      <Container>
        <Row>
          <Col col={10} xl={6}>
            <Title size="h1" lg="d3" slim={true}>
              Creating by experience,
              <br />
              building with <LinedText title="expertise"></LinedText>
            </Title>
          </Col>
        </Row>
      </Container>
    </Hero>
  )
}

export default OurStoryHero
