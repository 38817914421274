import React, { useState, useEffect } from "react"
import Section from "../Section"
import { useStaticQuery, graphql } from "gatsby"
import Row from "../Row"
import Col from "../Col"
import Title from "../Title"
import styled from "styled-components"
import ModalBootstrap from "../ModalBootstrap"
import LineVector from "../LineVector"
import LeaderEntry from "./LeaderEntry"
import { BREAKPOINTS } from "../../utils/styled_config"

import ChevronLeftIcon from "../Icons/ChevronLeftIcon"
import ChevronRightIcon from "../Icons/ChevronRightIcon"
import Carousel from "../Carousel"
import Container from "../Container"
import Element from "../UI/Element"
import ScaledImage from "../ScaledImage"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const LeadershipSection = () => {
  const [leader, setLeader] = useState()
  const [currentLeaderData, setCurrentLeaderData] = useState()
  const [mouseMoved, setMouseMoved] = useState(false)

  const [show, setShow] = useState(false)
  const handleClose = name => {
    setShow(false)
  }
  const handleShow = name => {
    if (!mouseMoved) {
      setLeader(name)
      setShow(true)
    }
  }

  const PrevArrow = ({ onClick, className }) => {
    return (
      <ArrowButton onClick={onClick} className={className}>
        <ChevronLeftIcon />
      </ArrowButton>
    )
  }

  const NextArrow = ({ onClick, className }) => {
    return (
      <ArrowButton onClick={onClick} className={className}>
        <ChevronRightIcon />
      </ArrowButton>
    )
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  const leadership = useStaticQuery(graphql`
    query LeadershipQuery {
      allLeadershipJson {
        nodes {
          id
          name
          position
          description
          img {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setCurrentLeaderData(
      leadership.allLeadershipJson.nodes.find(i => i.name === leader)
    )
  }, [leader, leadership.allLeadershipJson.nodes])

  return (
    <>
      <Section>
        <Container>
          <Title size={2} lg="d2" mb={10} mbLg={20} slim={true}>
            Our Leaders
          </Title>

          {leadership && (
            <Slider>
              <Carousel
                settings={settings}
                className="slick-spacing-leadership"
              >
                {leadership.allLeadershipJson.nodes.map(l => (
                  <div key={l.id}>
                    <LeaderEntry
                      name={l.name}
                      img={l.img}
                      position={l.position}
                      onMouseMove={() => setMouseMoved(true)}
                      onMouseDown={() => setMouseMoved(false)}
                      onMouseUp={() => handleShow(l.name)}
                    />
                  </div>
                ))}
              </Carousel>
            </Slider>
          )}
        </Container>
      </Section>

      {currentLeaderData && (
        <ModalBootstrap show={show} handleClose={handleClose}>
          <Row>
            <Col lg={5}>
              <Element mb={6}>
                <ScaledImage height={1} width={1} objectPosition="top">
                  <GatsbyImage
                    image={getImage(currentLeaderData.img)}
                    alt={currentLeaderData.name}
                  />
                </ScaledImage>
              </Element>
              <Title slim={true} size={3} lg={2} mb={2}>
                {currentLeaderData.name}
              </Title>
              <Position>{currentLeaderData.position}</Position>
              <LineVector height="12px" width="154px" />
            </Col>
            <Col lg={7}>
              <Element
                lineHeight={1.8}
                dangerouslySetInnerHTML={{
                  __html: currentLeaderData.description,
                }}
              ></Element>
            </Col>
          </Row>
        </ModalBootstrap>
      )}
    </>
  )
}

const Slider = styled.div`
  position: relative;

  .slick-dots {
    position: static;
  }
`

const Position = styled.div`
  font-weight: 500;
  margin-bottom: 16px;
`

const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  z-index: 2;
  background: none;

  svg {
    font-size: 20px;
  }

  &.slick-prev {
    left: -60px;
  }

  &.slick-next {
    right: -60px;
  }

  &.slick-disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    height: 56px;
    width: 56px;

    svg {
      font-size: 28px;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.lg}) {
    display: none;
  }
`

export default LeadershipSection
